import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
    mdiEye, mdiEyeOff, mdiCheck, mdiCheckDecagram, mdiCheckDecagramOutline ,  mdiCancel, mdiClose, mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiCloseCircle, mdiMenuDown, mdiMenu,
    mdiArrowLeft, mdiArrowRight, mdiSend, mdiCheckboxMarkedCircleOutline, mdiMagnify, mdiViewDashboardOutline, mdiAccountEditOutline, mdiLogout, mdiAccount, mdiInformationOutline,
    mdiTextBoxSearch, mdiTextBoxSearchOutline, mdiExitToApp, mdiClipboardOutline, mdiAccountBox, mdiHome, mdiAccountCircle, mdiChevronUp,
    mdiChevronDown, mdiPlay, mdiRestore, mdiStop
} from '@mdi/js'

//Usage '$vuetify.icons.xbox' or $xbox
const MY_ICONS = {
// jm added
    stop:mdiStop,
    loop:mdiRestore,
    play:mdiPlay,
    chevup: mdiChevronUp,
    chevdown:mdiChevronDown,
    person:mdiAccountCircle,
    home:mdiHome,
    /*Default List*/
    eye: mdiEye,
    eyeoff: mdiEyeOff,
    complete: mdiCheck,
    cancel: mdiCancel,
    close: mdiClose,
    delete: mdiCloseCircle, // delete (e.g. v-chip close)
    clear: mdiClose,
    success: mdiCheck,
    successOutline: mdiCheckDecagramOutline,
    successBold: mdiCheckDecagram,
    info: mdiInformationOutline,
    warning: '...',
    error: '...',
    prev: '...',
    next: '...',
    checkboxOn: mdiCheckboxMarked,
    checkboxOff: mdiCheckboxBlankOutline,
    checkboxIndeterminate: '...',
    delimiter: '...', // for carousel
    sort: '...',
    expand: mdiMenuDown,
    menu: mdiMenu,
    subgroup: '...',
    dropdown: mdiMenuDown,
    radioOn: '...',
    radioOff: '...',
    edit: '...',
    ratingEmpty: '...',
    ratingFull: '...',
    ratingHalf: '...',
    loading: '...',
    first: '...',
    last: '...',
    unfold: '...',
    file: '...',
    /*Additional Icons*/
    arrowLeft: mdiArrowLeft,
    arrowRight: mdiArrowRight,
    send: mdiSend,
    checkboxMarkedOutline: mdiCheckboxMarkedCircleOutline,
    magnify: mdiMagnify,
    appViewDash: mdiViewDashboardOutline,
    accountEdit: mdiAccountEditOutline, 
    logout: mdiLogout,
    account: mdiAccount,
    textSearch: mdiTextBoxSearch,
    textSearchOutline: mdiTextBoxSearchOutline,
    exitToApp: mdiExitToApp,
    clipboard: mdiClipboardOutline,

}


Vue.use(Vuetify);

export default new Vuetify({
    icons: {      
        values: MY_ICONS,
    },
    theme: {
        themes: {
            light: {
                background: '#778899',
                primary: '#283a44',
                secondary: '#71abcc',
                accent: '#446375',
                error: '#f44336',
                warning: '#ff9800',
                info: '#03a9f4',
                success: '#4caf50',
                cmpbk:'#D3DEDE'
            },
        },
        options: {
            customProperties: true
        },
    },
});
